import React from 'react'
import styled from "styled-components"
import { PointCircle } from '../common/PointCircle'
import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"

import LogoValues from "../../../images/recruit/recruitment_values.svg"

const Wrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: auto;

  text-align: center;
`

const Title = styled.h2`
  margin: 0;

  font-weight: 600;
  font-size: 64px;
  text-align: left;
  line-height: 96px;
  color: #FFFFFF;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-size: 24px;
    line-height: 36px;
  }
`

const MessageContainer = styled.div`
  width: 100%;
  margin-top: 24px;

  & p {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    text-align: left;
    color: #FFFFFF;

    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }
`

const PointContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 40px;
  }
`

const Logo = styled.img`
  width: 100%;
  height: auto;
`

export const RecruitmentValues: React.VFC = () => {
  return (
    <Wrapper>
      <Title>採用で大切にしていること</Title>
      <MessageContainer>
        <p>ネクストライブの採用では、ビジョンマッチを最も大切にしています。<br />会社がいくら立派なビジョンを掲げても、一人ひとりが"自分ごと"でなければただの絵空事だからです。<br />メンバーそれぞれが自分のビジョンと会社のビジョンに接点を持ち、<br />１つのゴールに向かってベクトルを合わせなければならないと考えています。<br />同じ信念や志を持つものが力を合わせることで、新たなイノベーションを起こせると信じています。</p>
      </MessageContainer>

      <PointContainer>
        <Logo src={LogoValues} alt="理念の共感×ポジティブ思考×Giver精神" />
      </PointContainer>
    </Wrapper>
  )
}