import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../../template/Layout"
import Head from "../../head";
import { 
  RESPONSIVE_STYLES,
  RECRUIT_PAGE_STYLES } from "../../AppConst"
import { ogpUrl } from "../../utlis/ImageUtil"
import { Props as BreadcrumbsData } from "../common/Breadcrumbs"
import { SectionTitle } from "../common/SectionTitle"
import { ImgWrapper } from "../common/ImageWrapper"

import { TopMessage } from "./top/TopMessage"
import { RecruitmentValues } from './top/RecruitmentValues'
import { CasualVisitCard } from './top/CasualVisitCard'
import { LinkCardItem } from './common/LinkCardItem'
import { RecruitIndustries } from './common/RecruitIndustries'
import { PickUpArticleList } from "./common/PickUpArticleList"

import culture from "../../images/recruit/icon_culture.svg"
import welfare from "../../images/recruit/icon_welfare.svg"
import environment from "../../images/recruit/icon_environment.svg"

/**
 * Styles
 */
const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`

const HeadLineContainer = styled.div`
  margin-top: 96px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    margin-top: 48px;
  }
`

const ValuesContainer = styled.div`
  background: #222222;
  padding: 160px 20px 160px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 80px 20px 80px;
  }
`

const CardItemContainer = styled.div`
  width: 100%;
  margin:auto;
  padding: 160px 0;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  background-color: #E9E9E9;

  @media(max-width: ${RECRUIT_PAGE_STYLES.ICON_CARD_RESPONSIVE_WIDTH}px){
    padding: 64px 0;
    flex-direction: column;
    gap: 40px;
  }
`

const CasualVisitContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    flex-direction: column;
  }
`

const CasualVisitLeftContainer = styled.div`
  width:50%;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width:100%;

    & > .gatsby-image-wrapper {
      height: calc(100vw * 200 / 375);
    }
  }
`

const CasualVisitRightContainer = styled.div`
  width:50%;
  background: #222222;
  display: flex;
  align-items: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width:100%;
  }
`

const BlogContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 160px 20px;
  background-color: #E9E9E9;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const PickUpArticleListWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
`

const RecruitmentContainer = styled.div`
  padding: 160px 20px 160px;
  background: #FFFFFF;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    padding: 64px 16px;
  }
`

const CardItemLink = [
  {
    image: culture,
    text: "カルチャー",
    caption: "culture",
    url: "/recruit/culture"
  },
  {
    image: welfare,
    text: "福利厚生",
    caption: "welfare",
    url: "/recruit/welfare"
  },
  {
    image: environment,
    text: "働く人・環境",
    caption: "environment",
    url: "/recruit/coworkers"
  },
]

/**
 * Props
 */
type Props = {
  headlineImg: any;
  casualVisit: any;
  recruitData: any;
  breadcrumbsData: BreadcrumbsData["values"]
}

/**
 * Component
 */
export const Recruit: React.FC<Props> = (props) => {
  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title="採用情報 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の採用情報ページです。"
        img={ogpUrl(process.env.NODE_ENV, props.headlineImg)}
      />
      <Wrapper>
        <SectionTitle title="RECRUIT" subTitle="採用情報"/>
        <HeadLineContainer>
          <Img fluid={props.headlineImg} alt="リクルートTOP"/>
        </HeadLineContainer>
        <ValuesContainer>
          <RecruitmentValues/>
        </ValuesContainer>
        <CardItemContainer>
          {CardItemLink.map((item, idx) => {
            return(
              <LinkCardItem
                key={idx}
                image={item.image} 
                text={item.text} 
                caption={item.caption} 
                url={item.url}/>
            )
          })}
        </CardItemContainer>
        <CasualVisitContainer>
          <CasualVisitLeftContainer>
            <Img fluid={props.casualVisit} alt="カジュアル面談" />
          </CasualVisitLeftContainer>
          <CasualVisitRightContainer>
            <CasualVisitCard />
          </CasualVisitRightContainer>
        </CasualVisitContainer>
        <RecruitmentContainer>
          <RecruitIndustries
            overView={props.recruitData.overView}
            title="募集職種"
            subTitle="JOB OPENING TYPE"
          />
        </RecruitmentContainer>
        <BlogContainer>
          <PickUpArticleListWrapper>
            <PickUpArticleList/>
          </PickUpArticleListWrapper>
        </BlogContainer>
      </Wrapper>
    </Layout>
  )
}
