import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { TriangleInCircleButton } from '../../common/TriangleInCircleButton'
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 37px;

  width: 100%;

  padding-bottom: 16px;
  border-bottom: 1px solid #222222;

  text-decoration: none;
  box-sizing: border-box;
  background: #FFFFFF;
  transition: 0.3s;

  &:hover {
    // figma上なかったのでコメントアウト
    //opacity: 0.7;
    //box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    & .outside-circle {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const IconWrapper = styled.div`
  margin-right:13px;
`

const Title = styled.h3`
  margin: 0;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.05em;
  }
`

const Text = styled.p`
  margin: 8px 0 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    display: none;
  }
`

type Props = {
  job: string
  text: string
  url: string
}

export const Industry: React.VFC<Props> = ({job, text, url}) => {
  return (
    <Wrapper to={url}>
      <TextWrapper>
        <Title>{job}</Title>
        <Text>
          {text}
        </Text>
      </TextWrapper>
      <IconWrapper>
        { /* TODO: leftで右向きになる。とりあえず暫定対応 */ }
        <TriangleInCircleButton direction='left' color='#222'/>
      </IconWrapper>
    </Wrapper>
  )
}
