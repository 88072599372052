import React from "react"
import { graphql } from "gatsby"
import { Recruit } from "../../components/recruit"

const breadcrumbsData: { text: string; path: string }[] = [
    { text: `RECRUIT`, path: `recruit` },
]

export default function RecruitPage({ data }) {
    const {
        headline,
        casualVisit,
        site: { siteMetadata: { recruitData } },
    } = data
    return (
        <Recruit
            breadcrumbsData={breadcrumbsData}
            headlineImg={headline.childImageSharp.fluid}
            casualVisit={casualVisit.childImageSharp.fluid}
            recruitData={recruitData}
        />
    )
}

export const query = graphql`
    query MyRecruitQuery {
      headline: file(relativePath: { eq: "images/recruit/img_recruit_top_header.png"}) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      casualVisit: file(relativePath: { eq: "images/recruit/img_recruit_top_casual_visit.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          recruitData {
            overView {
              job
              text
              url
            }
          }
        }
      }
    }
`
