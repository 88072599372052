import React from 'react'
import styled from 'styled-components'
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES } from "../../../AppConst"

import { Industry } from './Industry'
import { TitleAndCaption } from './TitleAndCaption'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const JobWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  width: 100%;
  margin: 80px auto 0;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    gap: 24px;
    margin: 40px auto 0;
  }
`

const JobBoxWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
  }
`

type Props = {
  overView: any
  title: string
  subTitle: string
}

export const RecruitIndustries:React.VFC<Props> = ({ overView, title, subTitle }) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} subTitle={subTitle}/>
      <JobWrapper>
        {overView.map((item, idx) => {
          return (
            <JobBoxWrapper key={idx} >
              <Industry job={item.job} text={item.text} url={item.url}/>
            </JobBoxWrapper>
          )
        })}
      </JobWrapper>
    </Wrapper>
  )
}
