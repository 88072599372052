import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

import { RESPONSIVE_STYLES, RECRUIT_PAGE_STYLES } from "../../../AppConst"

const { ICON_CARD_RESPONSIVE_WIDTH } = RECRUIT_PAGE_STYLES;

const Wrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 320px;
  height: 320px;

  text-decoration: none;
  background: #FFFFFF;
  transition: .3s;

  & > .icon {
    width: 120px;
    height: auto;
  }

  &:hover {
    box-shadow: 0px 0px 5px 5px #61B1C1;
  }

  @media(max-width: ${RESPONSIVE_STYLES.CARD_MAX_WIDTH}px){
    width: 100%;
    max-width: 320px;
  }
`

const Text = styled.h3`
  margin: 24px auto 0;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #222222;
`

const Caption = styled.p`
  margin: 3px auto 0;

  font-family: 'Righteous';

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
`

type Props = {
  image: string
  text: string
  caption: string
  url?: string
}

export const LinkCardItem: React.VFC<Props> = (props) => {
  return (
    <Wrapper className='card-wrapper' to={props.url}>
      <img className='icon' alt="リンク先アイコン" src={props.image}/>
      <Text>{props.text}</Text>
      <Caption>{props.caption}</Caption>
    </Wrapper>
  )
}
