import React from 'react'
import styled from 'styled-components'

import { TitleAndCaption } from '../common/TitleAndCaption'
import { RoundLinkButton } from "../../common/RoundLinkButton"
import { RESPONSIVE_STYLES } from "../../../AppConst"

const Wrapper = styled.div`
  margin: 0 0 0 96px;
  box-sizing: border-box;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 100%;
    margin: 0px;
    padding: 64px 16px;
    text-align: center;

    & > h2, p {
      text-align: left;
    }
  }
`

const Message = styled.div`
  max-width: 440px;
  margin-top: 24px;

  & p {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    max-width: none;
    width: 100%;
    text-align: left;
  }
`

const ButtonArea = styled.div`
  max-width: 440px;
  margin-top: 48px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    display: inline-flex;
    width: 100%;
    max-width: none;
  }
`

export const CasualVisitCard = () => {
  return (
    <Wrapper>
      <TitleAndCaption title='カジュアル面談' subTitle='CASUAL VISIT' color="#ffffff" />
      <Message>
        <p>ネクストライブがあなたの求める環境かどうか確かめていただくために、気軽にお話しするカジュアル面談をご用意しています。<br />転職はまだ考えてないけど、少し話を聞いてみたいという方も大歓迎です。</p>
      </Message>
      <ButtonArea>
        <RoundLinkButton title='カジュアル面談について詳しく見る' url='/recruit/casualvisit'/>
      </ButtonArea>
    </Wrapper>
  )
}
